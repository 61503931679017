export const MARKETO_PARAMS_EXPIRE_AT_KEY = "marketo-params-expire-at";
export const NO_GATE_KEY = "no-gate";

// Unix timestamp from 12/6/22, and the Xenium SW docs pushbutton meeting.
// Force-resets the EULA flag so users have to fill it out again.
export const SOFTWARE_EULA_KEY = "software-eula-1670352297575";

export const VERBOSE_MARKETO_PARAMS = Object.freeze(
  new Set([
    "campaignID",
    "Last_Touch_Campaign__c",
    "Last_Touch_Subsource__c",
    "Last_Touch_Source__c",
  ]),
);
