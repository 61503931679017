import {
  fontAssetCalibreMediumName,
  fontAssetCalibreRegularName,
  fontAssetCalibreSemiboldName,
  fontWeightMedium,
  fontWeightRegular,
  fontWeightSemibold,
} from "@10xdev/design-tokens";
import calibreWebMedium from "@10xdev/design-tokens/assets/CalibreWeb-Medium.woff2";
import calibreWebRegular from "@10xdev/design-tokens/assets/CalibreWeb-Regular.woff2";
import calibreWebSemibold from "@10xdev/design-tokens/assets/CalibreWeb-Semibold.woff2";
import { css, Global } from "@emotion/react";

const Fonts = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: ${fontAssetCalibreRegularName};
          font-style: normal;
          font-weight: ${fontWeightRegular};
          src: url(${calibreWebRegular}) format("woff2"),
            url("https://cdn.10xgenomics.com/raw/upload/v1576708571/fonts/CalibreWeb-Regular.woff")
              format("woff");
        }

        @font-face {
          font-family: ${fontAssetCalibreMediumName};
          font-style: normal;
          font-weight: ${fontWeightMedium};
          src: url(${calibreWebMedium}) format("woff2"),
            url("https://cdn.10xgenomics.com/raw/upload/v1576708571/fonts/CalibreWeb-Medium.woff")
              format("woff");
        }

        @font-face {
          font-family: ${fontAssetCalibreSemiboldName};
          font-style: normal;
          font-weight: ${fontWeightSemibold};
          src: url(${calibreWebSemibold}) format("woff2"),
            url("https://cdn.10xgenomics.com/raw/upload/v1576708571/fonts/CalibreWeb-Semibold.woff")
              format("woff");
        }
      `}
    />
  );
};

export default Fonts;
