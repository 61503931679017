export const SUBTITLE = "10x Genomics";
export const SUPPORT_SUBTITLE = "Official 10x Genomics Support";

/**
 * This function returns the domain for page metadata.
 */
export const getDomain = () => {
  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    // Local development
    case "development":
      return "http://localhost:3000";

    // Vercel preview deployments
    case "preview":
      return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;

    // Production, and any other case
    default:
      return "https://www.10xgenomics.com";
  }
};

/**
 * This function returns a clean title for page metadata.
 *
 * A title should always be passed in, but there are odd
 * cases where it isn't. Quick and simple to handle.
 *
 * In addition, per https://10xtech.atlassian.net/browse/WEB-1908,
 * Support should have a distinct subtitle from the rest
 * of the site. We check the current path to figure that out.
 */
export const getFullTitle = (
  title?: string | null | undefined,
  asPath?: string | undefined,
): string => {
  const cleanedTitle = title ? `${title} - ` : "";

  if (asPath?.split("/").includes("support")) {
    return cleanedTitle + SUPPORT_SUBTITLE;
  }
  return cleanedTitle + SUBTITLE;
};
