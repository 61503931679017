import type { TextSize, TextWeight } from "@10xdev/types";
import {
  colorBlack,
  colorBlueLight,
  colorBlueMedium,
  colorGrayLightest,
  colorGreyscale80,
  colorMaroon,
  colorNeonGreenLight,
  colorProductCnv,
  colorRed,
  colorSteelDark,
  colorSteelDarker,
  colorSteelDarkest,
  colorSteelLight,
  colorSteelLighter,
  colorSteelMedium,
  colorSuccessBase,
  colorWhite,
  fontFamilyBase,
  fontLetterSpacingLarge,
  fontLetterSpacingMedium,
  fontLetterSpacingSmall,
  fontLetterSpacingXlarge,
  fontLetterSpacingXsmall,
  fontLetterSpacingXxlarge,
  fontLetterSpacingXxsmall,
  fontLetterSpacingXxxlarge,
  fontLetterSpacingXxxsmall,
  fontLetterSpacingXxxxlarge,
  fontLineHeightLarge,
  fontLineHeightMedium,
  fontLineHeightSmall,
  fontLineHeightXlarge,
  fontLineHeightXsmall,
  fontLineHeightXxlarge,
  fontLineHeightXxsmall,
  fontLineHeightXxxlarge,
  fontLineHeightXxxsmall,
  fontLineHeightXxxxlarge,
  fontSizeLarge,
  fontSizeMedium,
  fontSizeSmall,
  fontSizeXlarge,
  fontSizeXsmall,
  fontSizeXxlarge,
  fontSizeXxsmall,
  fontSizeXxxlarge,
  fontSizeXxxsmall,
  fontSizeXxxxlarge,
  fontWeightMedium,
  fontWeightRegular,
  fontWeightSemibold,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { HTMLProps, ReactNode } from "react";
import { forwardRef } from "react";

// Select props to pass through to the element, ex: passing props for
// next/link component
type PassThroughProps = Pick<
  HTMLProps<HTMLElement>,
  "onClick" | "href" | "style" | "id"
>;

interface Props extends PassThroughProps {
  /**
   * Specifies, polymorphically, the underlying HTML element.
   *
   * **The semantic meaning of a text element should be decoupled
   * from its presentation.** Without this separation, text becomes
   * inflexible and difficult to work with.
   */
  as: keyof JSX.IntrinsicElements;

  children: ReactNode;

  className?: string;

  /** An enumerated color. */
  color?: keyof typeof colors;

  /** An optional gradient text color */
  gradient?: string;

  /**
   * Specifies whether the component should behave differently
   * on smaller screens.
   *
   * The media-aware behavior rules are straightforward:
   * if responsive is set to `true` then we inspect the
   * passed size and use the next size down. So for example,
   * if the passed size is `xlarge`, then we use `large`
   * instead on smaller screens. "Smaller" means screens
   * less than 900px wide, vaguely a tablet in horizontal
   * orientation.
   *
   * This behavior is opt-in. By default, the component renders
   * at the passed size regardless of media context.
   * */
  responsive?: boolean;

  /** An enumerated text size. */
  size: TextSize;

  /** An enumerated font weight. */
  weight?: TextWeight;
}

export const colors = {
  base: colorSteelDarkest,
  black: colorBlack,
  blue: colorBlueMedium,
  blueLight: colorBlueLight,
  blueMedium: colorBlueMedium,
  gray: colorSteelMedium,
  grayLightest: colorGrayLightest,
  grayScale80: colorGreyscale80,
  greenLight: colorNeonGreenLight,
  inherit: "inherit",
  maroon: colorMaroon,
  midgray: colorSteelDarker,
  purple: colorProductCnv,
  red: colorRed,
  success: colorSuccessBase,
  steelDark: colorSteelDark,
  steelDarker: colorSteelDarker,
  steelDarkest: colorSteelDarkest,
  steelLight: colorSteelLight,
  steelLighter: colorSteelLighter,
  steelMedium: colorSteelMedium,
  transparent: "transparent",
  white: colorWhite,
};

export const sizes: Array<TextSize> = [
  "xxxxlarge",
  "xxxlarge",
  "xxlarge",
  "xlarge",
  "large",
  "medium",
  "small",
  "xsmall",
  "xxsmall",
  "xxxsmall",
];

export const weights = {
  medium: fontWeightMedium,
  regular: fontWeightRegular,
  semibold: fontWeightSemibold,
};

const sizeStyles: Record<TextSize, SerializedStyles> = {
  xxxxlarge: css`
    font-size: ${fontSizeXxxxlarge};
    letter-spacing: ${fontLetterSpacingXxxxlarge};
    line-height: ${fontLineHeightXxxxlarge};
  `,

  xxxlarge: css`
    font-size: ${fontSizeXxxlarge};
    letter-spacing: ${fontLetterSpacingXxxlarge};
    line-height: ${fontLineHeightXxxlarge};
  `,

  xxlarge: css`
    font-size: ${fontSizeXxlarge};
    letter-spacing: ${fontLetterSpacingXxlarge};
    line-height: ${fontLineHeightXxlarge};
  `,

  xlarge: css`
    font-size: ${fontSizeXlarge};
    letter-spacing: ${fontLetterSpacingXlarge};
    line-height: ${fontLineHeightXlarge};
  `,

  large: css`
    font-size: ${fontSizeLarge};
    letter-spacing: ${fontLetterSpacingLarge};
    line-height: ${fontLineHeightLarge};
  `,

  medium: css`
    font-size: ${fontSizeMedium};
    letter-spacing: ${fontLetterSpacingMedium};
    line-height: ${fontLineHeightMedium};
  `,

  small: css`
    font-size: ${fontSizeSmall};
    letter-spacing: ${fontLetterSpacingSmall};
    line-height: ${fontLineHeightSmall};
  `,

  xsmall: css`
    font-size: ${fontSizeXsmall};
    letter-spacing: ${fontLetterSpacingXsmall};
    line-height: ${fontLineHeightXsmall};
  `,

  xxsmall: css`
    font-size: ${fontSizeXxsmall};
    letter-spacing: ${fontLetterSpacingXxsmall};
    line-height: ${fontLineHeightXxsmall};
  `,

  xxxsmall: css`
    font-size: ${fontSizeXxxsmall};
    letter-spacing: ${fontLetterSpacingXxxsmall};
    line-height: ${fontLineHeightXxxsmall};
  `,
};

const responsiveSizesStyles: Record<TextSize, SerializedStyles> = {
  xxxxlarge: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXxlarge};
      letter-spacing: ${fontLetterSpacingXxlarge};
      line-height: ${fontLineHeightXxlarge};
    }
  `,
  xxxlarge: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXxlarge};
      letter-spacing: ${fontLetterSpacingXxlarge};
      line-height: ${fontLineHeightXxlarge};
    }
  `,
  xxlarge: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXlarge};
      letter-spacing: ${fontLetterSpacingXlarge};
      line-height: ${fontLineHeightXlarge};
    }
  `,
  xlarge: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeLarge};
      letter-spacing: ${fontLetterSpacingLarge};
      line-height: ${fontLineHeightLarge};
    }
  `,
  large: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeMedium};
      letter-spacing: ${fontLetterSpacingMedium};
      line-height: ${fontLineHeightMedium};
    }
  `,
  medium: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeSmall};
      letter-spacing: ${fontLetterSpacingSmall};
      line-height: ${fontLineHeightSmall};
    }
  `,
  small: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXsmall};
      letter-spacing: ${fontLetterSpacingXsmall};
      line-height: ${fontLineHeightXsmall};
    }
  `,
  xsmall: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXxsmall};
      letter-spacing: ${fontLetterSpacingXxsmall};
      line-height: ${fontLineHeightXxsmall};
    }
  `,
  xxsmall: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXxxsmall};
      letter-spacing: ${fontLetterSpacingXxxsmall};
      line-height: ${fontLineHeightXxxsmall};
    }
  `,
  xxxsmall: css`
    @media (max-width: ${mediaTabletLandscape}) {
      font-size: ${fontSizeXxxsmall};
      letter-spacing: ${fontLetterSpacingXxxsmall};
      line-height: ${fontLineHeightXxxsmall};
    }
  `,
};

/**
 * A generic typography primitive used to render all text in this project.
 * If you're displaying text, you should be using this component.
 * It provides various conveniences and enforces the design system.
 *
 * See also the `Heading` component, which provides a thin but additional
 * layer of abstraction on top of `Text`.
 */
// https://github.com/yannickcr/eslint-plugin-react/issues/3015
export const Text = forwardRef<any, Props>(
  (
    {
      as,
      children,
      className,
      gradient,
      color = "base",
      responsive = false,
      size,
      weight = "regular",
      ...htmlProps
    },
    ref,
  ) => {
    const baseCss = css({
      MozOsxFontSmoothing: "grayscale",
      WebkitFontSmoothing: "antialiased",
      boxSizing: "border-box",
      color: (color && colors[color]) || colorSteelDarkest,
      ...(gradient && {
        backgroundClip: "text",
        backgroundImage: gradient,
        color: "transparent",
      }),
      fontFamily: fontFamilyBase,
      fontStyle: "normal",
      fontWeight: weights[weight] || fontWeightRegular,
      margin: 0,
      padding: 0,
    });

    const Element = styled(as)([
      baseCss,
      sizeStyles[size],
      responsive ? responsiveSizesStyles[size] : undefined,
    ]);

    return (
      <Element className={className} ref={ref} {...htmlProps}>
        {children}
      </Element>
    );
  },
);

export default Text;
